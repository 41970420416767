import React, { useEffect, useRef, useState } from 'react'
import * as styles from './index.module.scss'

interface ParticleSettings {
  cp: number
  cg: number
  cn: number
  w: number
  maxV: number
}

class Particle {
  constructor(width: number, height: number, settings: ParticleSettings) {
    this.x = Math.random() * width
    this.y = Math.random() * height
    this.vx = Math.random() * width - width / 2
    this.vy = Math.random() * height - height / 2
    this.el = document.createElement('div')
    this.el.classList.add(styles.particle)
    this.el.innerText = '→'
    this.updateSettings(settings)
  }

  public readonly el: HTMLElement
  public x: number
  public y: number
  private vx: number
  private vy: number
  private nearParticle: Particle | null = null
  private ownBest: { x: number; y: number; d: number; goal: Goal } | null = null
  private cp: number
  private cg: number
  private cn: number
  private w: number
  private maxV: number

  updateSettings(settings: ParticleSettings) {
    this.cp = settings.cp
    this.cg = settings.cg
    this.cn = settings.cn
    this.w = settings.w
    this.maxV = settings.maxV
  }
  updatePosition(elaspedTime: number) {
    this.x = this.x + (elaspedTime / 1000) * this.vx
    this.y = this.y + (elaspedTime / 1000) * this.vy
    this.el.style.transform = [
      `translateX(${this.x - 10}px)`,
      `translateY(${this.y - 10}px)`,
      `rotate(${this.r}rad)`,
    ].join(' ')
  }
  updateOwnBest(goal: Goal) {
    const d = this.distanceTo(goal.x, goal.y)
    if (this.ownBest === null || d < this.ownBest.d) {
      this.ownBest = { x: this.x, y: this.y, d, goal }
    }
  }
  distanceTo(x: number, y: number) {
    return Math.sqrt((this.x - x) * (this.x - x) + (this.y - y) * (this.y - y))
  }
  updateVerocity(tx: number, ty: number) {
    this.vx = this.w * this.vx + this.cg * Math.random() * (tx - this.x)
    this.vy = this.w * this.vy + this.cg * Math.random() * (ty - this.y)
    if (this.ownBest && this.cp) {
      this.vx += this.cp * Math.random() * (this.ownBest.x - this.x)
      this.vy += this.cp * Math.random() * (this.ownBest.y - this.y)
    }
    if (this.nearParticle && this.cn) {
      const nd = this.distanceTo(this.nearParticle.x, this.nearParticle.y)
      this.vx -= (this.cn / nd / nd) * (this.nearParticle.x - this.x) * 100
      this.vy -= (this.cn / nd / nd) * (this.nearParticle.y - this.y) * 100
    }
    if (this.maxV) {
      const v = Math.sqrt(this.vx * this.vx + this.vy * this.vy)
      if (this.maxV < v) {
        this.vx = (this.maxV * this.vx) / v
        this.vy = (this.maxV * this.vy) / v
      }
    }
  }
  updateNearParticle(particles: ReadonlyArray<Particle>) {
    let minDistance = Number.MAX_VALUE
    for (const p of particles) {
      let d = this.distanceTo(p.x, p.y)
      if (0 < d && d < minDistance) {
        this.nearParticle = p
        minDistance = d
      }
    }
  }
  resetOwnBest(goal: Goal) {
    if (this.ownBest && this.ownBest.goal === goal) {
      this.ownBest = null
    }
  }
  get r() {
    const v = Math.sqrt(this.vx * this.vx + this.vy * this.vy)
    return this.vy < 0
      ? Math.PI * 2 - Math.acos(this.vx / v)
      : Math.acos(this.vx / v)
  }
}

class Goal {
  constructor(public readonly x: number, public readonly y: number) {
    this.el = document.createElement('div')
    this.el.classList.add(styles.goal)
    this.el.style.transform = [
      `translateX(${this.x - 20}px)`,
      `translateY(${this.y - 20}px)`,
    ].join(' ')
  }
  public readonly el: HTMLElement
  public nearCount = 0
}

interface ParticleSwarmProps {
  settings?: Partial<ParticleSettings>
  numParticles?: number
  numGoals?: number
  showControl?: boolean
}

const DEFAULT_PATICLE_SETTINGS = {
  cp: 1,
  cg: 1,
  cn: 1,
  w: 0.99,
  maxV: 300,
} as ParticleSettings
const DEFAULT_NUM_PARTICLES = 100
const DEFAULT_NUM_GOALS = 1

export default function ParticleSwarm(props: ParticleSwarmProps) {
  const fieldRef = useRef<HTMLDivElement>(null)
  const particlesRef = useRef<Array<Particle>>(null)
  const goalsRef = useRef<Array<Goal>>(null)
  const [settings, setSettings] = useState<ParticleSettings>({
    ...DEFAULT_PATICLE_SETTINGS,
    ...(props.settings || {}),
  })
  const [numParticles, setNumParticles] = useState<number>(
    props.numParticles || DEFAULT_NUM_PARTICLES
  )
  const [numGoals, setNumGoals] = useState<number>(
    props.numGoals || DEFAULT_NUM_GOALS
  )
  useEffect(() => {
    setSettings({ ...DEFAULT_PATICLE_SETTINGS, ...(props.settings || {}) })
  }, [props.settings])
  useEffect(() => {
    setNumParticles(props.numParticles || DEFAULT_NUM_PARTICLES)
  }, [props.numParticles])
  useEffect(() => {
    setNumGoals(props.numGoals || DEFAULT_NUM_GOALS)
  }, [props.numGoals])
  useEffect(() => {
    if (fieldRef.current) {
      const { width, height } = fieldRef.current.getBoundingClientRect()
      if (particlesRef.current === null) {
        const particles = Array.from({ length: numParticles }).map(
          () => new Particle(width, height, settings)
        )
        for (const p of particles) {
          fieldRef.current.appendChild(p.el)
        }
        particlesRef.current = particles
      } else if (particlesRef.current.length < numParticles) {
        const additionalNum = numParticles - particlesRef.current.length
        const additionalParticles = Array.from({ length: additionalNum }).map(
          () => new Particle(width, height, settings)
        )
        for (const p of additionalParticles) {
          fieldRef.current.appendChild(p.el)
        }
        particlesRef.current = particlesRef.current.concat(additionalParticles)
      } else {
        const deleted = particlesRef.current.splice(numParticles)
        for (const p of deleted) {
          if (fieldRef.current.contains(p.el)) {
            fieldRef.current.removeChild(p.el)
          }
        }
      }
    }
  }, [fieldRef.current, numParticles])
  useEffect(() => {
    if (fieldRef.current) {
      const { width, height } = fieldRef.current.getBoundingClientRect()
      if (goalsRef.current === null) {
        const goals = Array.from({ length: numGoals }).map(
          () => new Goal(Math.random() * width, Math.random() * height)
        )
        for (const g of goals) {
          fieldRef.current.appendChild(g.el)
        }
        goalsRef.current = goals
      } else if (goalsRef.current.length < numGoals) {
        const additionalNum = numGoals - goalsRef.current.length
        const additionalGoals = Array.from({ length: additionalNum }).map(
          () => new Goal(Math.random() * width, Math.random() * height)
        )
        for (const g of additionalGoals) {
          fieldRef.current.appendChild(g.el)
        }
        goalsRef.current = goalsRef.current.concat(additionalGoals)
      } else {
        const deleted = goalsRef.current.splice(numGoals)
        for (const g of deleted) {
          if (fieldRef.current.contains(g.el)) {
            fieldRef.current.removeChild(g.el)
          }
          for (const p of particlesRef.current || []) {
            p.resetOwnBest(g)
          }
        }
      }
    }
  }, [fieldRef.current, numGoals])
  const updateGoal = (oldGoal: Goal, newGoal: Goal = null) => {
    if (fieldRef.current && goalsRef.current) {
      if (fieldRef.current.contains(oldGoal.el)) {
        fieldRef.current.removeChild(oldGoal.el)
      }
      if (newGoal === null) {
        const { width, height } = fieldRef.current.getBoundingClientRect()
        newGoal = new Goal(Math.random() * width, Math.random() * height)
      }
      fieldRef.current.appendChild(newGoal.el)
      goalsRef.current = goalsRef.current
        .filter(g => g !== oldGoal)
        .concat([newGoal])
    }
    for (const p of particlesRef.current || []) {
      p.resetOwnBest(oldGoal)
    }
  }
  useEffect(() => {
    let previousTimeStamp: number
    let elapsedAfterAdjust = 0
    let requestAnimationFrameId: number
    const step = (timestamp: number) => {
      const particles = particlesRef.current
      if (previousTimeStamp !== undefined && particles && goalsRef.current) {
        const elapsed = timestamp - previousTimeStamp
        elapsedAfterAdjust += elapsed
        if (100 < elapsedAfterAdjust) {
          let bestP: Particle,
            minD = Number.MAX_VALUE
          for (const p of particles) {
            for (const goal of goalsRef.current) {
              const d = p.distanceTo(goal.x, goal.y)
              if (d < minD) {
                bestP = p
                minD = d
              }
              if (d < 40) goal.nearCount++
            }
          }
          for (const p of particles) {
            p.updateVerocity(bestP.x, bestP.y)
          }
          for (const goal of goalsRef.current) {
            if (100 < goal.nearCount) updateGoal(goal)
          }
          elapsedAfterAdjust = 0
        }
        for (const p of particles) {
          p.updatePosition(elapsed)
          for (const goal of goalsRef.current) {
            p.updateOwnBest(goal)
          }
        }
        for (const p of particles) {
          p.updateNearParticle(particles)
        }
      }
      previousTimeStamp = timestamp
      requestAnimationFrameId = requestAnimationFrame(step)
    }
    requestAnimationFrameId = requestAnimationFrame(step)
    return () => cancelAnimationFrame(requestAnimationFrameId)
  }, [])
  useEffect(() => {
    for (const p of particlesRef.current || []) {
      p.updateSettings(settings)
    }
  }, [settings])

  const handleClick = (e: React.PointerEvent) => {
    if (goalsRef.current && fieldRef.current) {
      const { left, top } = fieldRef.current.getBoundingClientRect()
      updateGoal(goalsRef.current[0], new Goal(e.clientX - left, e.clientY - top))
    }
  }
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value)
    if (!isNaN(value)) setSettings({ ...settings, [e.target.name]: value })
  }
  const handleChangeNumParticles = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value)
    if (!isNaN(value)) setNumParticles(value)
  }
  const handleChangeNumGoals = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value)
    if (!isNaN(value)) setNumGoals(value)
  }
  return (
    <div className={styles.container}>
      <div
        className={styles.field}
        ref={fieldRef}
        onPointerDown={handleClick}
      ></div>
      {props.showControl && (
        <div className={styles.control}>
          <label>
            <span>cognitive coefficient:</span>
            <input
              name="cp"
              type="number"
              value={settings.cp}
              onChange={handleChange}
            ></input>
          </label>
          <label>
            <span>social coefficient:</span>
            <input
              name="cg"
              type="number"
              value={settings.cg}
              onChange={handleChange}
            ></input>
          </label>
          <label>
            <span>coefficient to avoid near particle:</span>
            <input
              name="cn"
              type="number"
              value={settings.cn}
              onChange={handleChange}
            ></input>
          </label>
          <label>
            <span>inertia weight:</span>
            <input
              name="w"
              type="number"
              value={settings.w}
              onChange={handleChange}
            ></input>
          </label>
          <label>
            <span>max velocity cap:</span>
            <input
              name="maxV"
              type="number"
              value={settings.maxV}
              onChange={handleChange}
            ></input>
          </label>
          <label>
            <span>number of particles:</span>
            <input
              type="number"
              value={numParticles}
              onChange={handleChangeNumParticles}
            ></input>
          </label>
          <label>
            <span>number of goals:</span>
            <input
              type="number"
              value={numGoals}
              onChange={handleChangeNumGoals}
            ></input>
          </label>
        </div>
      )}
    </div>
  )
}
