import React from 'react'

import { COLORS_BY_NUM_COLORS, type Matrix } from './ParticleLifeLogic'

export type RuleMatrixChangeParam = {
  i: number
  j: number
  value: number
}

type RuleMatrixTableProps = {
  numColors: number
  matrix: Matrix | null
  onChange: (param: RuleMatrixChangeParam) => void
}
export default function RuleMatrixTable({
  numColors,
  matrix,
  onChange,
}: RuleMatrixTableProps) {
  if (matrix == null || matrix.length === 0 || matrix[0].length === 0) return <></>
  const colors = COLORS_BY_NUM_COLORS[numColors - 1]
  const size = colors.length
  const grid = Array(size + 1)
    .fill(null)
    .map(() => Array(size + 1).fill(null))

  for (let i = 0; i < size; i++) {
    for (let j = 0; j < size; j++) {
      if (matrix[i] && matrix[i][j] != null) {
        grid[i + 1][j + 1] = Math.round(matrix[i][j] * 10) / 10
      }
    }
  }

  const getChangeHandler = (i: number, j: number) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = parseFloat(e.target.value)
      if (!isNaN(value)) onChange({ i, j, value })
    }
  }
  const getCellColor = (v: number) => {
    if (v === 0) {
      return '#ffffff'
    } else {
      const cv = Math.floor(v * 128)
      if (v < 0) return `rgb(255,${255 + cv},${255 + cv})`
      return `rgb(${255 - cv},255,${255 - cv})`
    }
  }

  return (
    <table style={{ borderCollapse: 'collapse' }}>
      <thead>
        <tr>
          <th></th>
          {grid[0].slice(1).map((_, index) => (
            <th key={index} style={{ color: colors[index], lineHeight: '1rem' }}>
              ●
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {grid.slice(1).map((row, rowIndex) => (
          <tr key={rowIndex}>
            {row.map((value, cellIndex) =>
              cellIndex === 0 ? (
                <th
                  key={cellIndex}
                  style={{ color: colors[rowIndex], lineHeight: '1rem' }}
                >
                  ●
                </th>
              ) : (
                <td
                  key={cellIndex}
                  style={{
                    padding: 0,
                    maxWidth: `${100 / size}%`,
                    lineHeight: '1em',
                  }}
                >
                  <input
                    style={{
                      margin: 0,
                      width: '100%',
                      border: 'none',
                      textAlign: 'center',
                      lineHeight: '1rem',
                      backgroundColor: getCellColor(value)
                    }}
                    type="number"
                    max="1"
                    min="-1"
                    step="0.1"
                    value={value}
                    onChange={getChangeHandler(rowIndex, cellIndex - 1)}
                  />
                </td>
              )
            )}
          </tr>
        ))}
      </tbody>
    </table>
  )
}
