import React, { useEffect, useRef, useState } from 'react'
import * as styles from './index.module.scss'


function getDistances(
  x: number,
  y: number,
  width: number,
  height: number,
  sourceX: number,
  sourceY: number
) {
  const xDistances = [
      x + width + width + sourceX,
      width - x + width + sourceX,
      x + sourceX,
      x - sourceX,
      width - sourceX + width - x,
      width - sourceX + width + x,
      width - sourceX + width + width + width - x,
    ],
    yDistances = [
      y + height + height + sourceY,
      height - y + height + sourceY,
      y + sourceY,
      y - sourceY,
      height - sourceY + height - y,
      height - sourceY + height + y,
      height - sourceY + height + height + height - y,
    ]
  const d = []
  for (const xd of xDistances) {
    for (const yd of yDistances) {
      d.push(Math.sqrt(xd * xd + yd * yd))
    }
  }
  return d
}

class Point {
  constructor(
    public readonly x: number,
    public readonly y: number,
    public readonly distances: ReadonlyArray<number>
  ) {}

  public maxValue: number = 0
}

function initializePoints(
  width: number,
  height: number,
  sourceX: number,
  sourceY: number,
): ReadonlyArray<Point> {
  const points = []
  for (let x = 0; x < width; x++) {
    for (let y = 0; y < height; y++) {
      points.push(
        new Point(x, y, getDistances(x, y, width, height, sourceX, sourceY))
      )
    }
  }
  return points
}

const SAMPLE_COUNT = 50
const ATTENUATION_DISTANCE = 300

const WIDTH = 100

type RChangeEvent = React.ChangeEvent<HTMLInputElement>

export default function ChladniFigure() {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const [frequency, setFrequency] = useState(50)
  const [verocity, setVerocity] = useState(2000)
  const [amplitudeThreshold, setAmplitudeThreshold] = useState(2)
  const [height, setHeight] = useState(100)
  const [sourceX, setsSourceX] = useState(WIDTH / 2)
  const [sourceY, setsSourceY] = useState(height / 2)
  const [waveMode, setWaveMode] = useState(false)
  useEffect(() => {
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext('2d')
      const points = initializePoints(WIDTH, height, sourceX, sourceY)
      const waveLength = verocity / frequency
      let requestAnimationFrameId: number
      
      if (waveMode) {
        ctx.fillStyle = `rgb(127, 127, 127)`
        ctx.fillRect(0, 0, WIDTH, height)
        let startTimestamp: number
        const step = (timestamp: number) => {
          if (startTimestamp === undefined) {
            startTimestamp = timestamp
          }
          const t = timestamp - startTimestamp
          for (const point of points) {
            let accumlated = 0
            for (const d of point.distances) {
              if (d < ATTENUATION_DISTANCE) {
                const attunate_ratio =
                  (ATTENUATION_DISTANCE - d) / ATTENUATION_DISTANCE
                accumlated +=
                  attunate_ratio *
                  Math.sin((2 * Math.PI * (d - verocity * t)) / waveLength)
              }
            }
            if (accumlated < -8) {
              ctx.fillStyle = `rgb(0,0,0)`
            } else if (8 < accumlated) {
              ctx.fillStyle = `rgb(255,255,255)`
            } else {
              const c = accumlated / 8 * 127 + 127
              ctx.fillStyle = `rgb(${c},${c},${c})`
            }
            ctx.fillRect(point.x, point.y, 1, 1)
          }
          requestAnimationFrameId = requestAnimationFrame(step)
        }
        requestAnimationFrameId = requestAnimationFrame(step)
      } else {
        ctx.fillStyle = `white`
        ctx.fillRect(0, 0, WIDTH, height)
        const waveSpan = 1 / frequency / 2
        let count = 0
        const step = () => {
          const t = Math.random() * waveSpan
          for (const point of points) {
            if (point.maxValue > amplitudeThreshold) {
              continue
            }
            let accumlated = 0
            for (const d of point.distances) {
              if (d < ATTENUATION_DISTANCE) {
                const attunate_ratio =
                  (ATTENUATION_DISTANCE - d) / ATTENUATION_DISTANCE
                accumlated +=
                  attunate_ratio *
                  Math.sin((2 * Math.PI * (d - verocity * t)) / waveLength)
              }
            }
            const absAccumlated = Math.abs(accumlated)
            if (point.maxValue < absAccumlated) {
              point.maxValue = absAccumlated
              const c =
                point.maxValue > amplitudeThreshold
                  ? 0
                  : (1 - point.maxValue / amplitudeThreshold) * 255
              ctx.fillStyle = `rgb(${c},${c},${c})`
              ctx.fillRect(point.x, point.y, 1, 1)
            }
          }
          count++
          if (count < SAMPLE_COUNT) {
            requestAnimationFrameId = requestAnimationFrame(step)
          } else {
            requestAnimationFrameId = null
          }
        }
        requestAnimationFrameId = requestAnimationFrame(step)
      }
      return () => {
        if (requestAnimationFrameId) {
          cancelAnimationFrame(requestAnimationFrameId)
        }
      }
    }
  }, [frequency, verocity, amplitudeThreshold, height, sourceX, sourceY, waveMode])
  const handleFrequencyChange = (evnet: RChangeEvent) =>
    setFrequency(parseFloat(evnet.target.value))
  const handleVelocityChange = (evnet: RChangeEvent) =>
    setVerocity(parseInt(evnet.target.value))
  const handleAmplitudeThresholdChange = (evnet: RChangeEvent) =>
    setAmplitudeThreshold(parseFloat(evnet.target.value))
  const handleHeightChange = (evnet: RChangeEvent) => {
    const value = parseInt(evnet.target.value)
    setHeight(value)
    if (value < sourceY) setsSourceY(value)
  }
  const handleSourceXChange = (evnet: RChangeEvent) =>
    setsSourceX(parseInt(evnet.target.value))
  const handleSourceYChange = (evnet: RChangeEvent) =>
    setsSourceY(parseInt(evnet.target.value))
  const handleWaveModeChange = (evnet: RChangeEvent) =>
    setWaveMode(evnet.target.checked)
  const numberInputs = (
    min: number,
    max: number,
    step: number,
    value: number,
    handelr: (evnet: RChangeEvent) => void
  ) => {
    return (
      <>
        <input
          type="range"
          min={min}
          max={max}
          step={step}
          value={value}
          onChange={handelr}
        ></input>
        <input
          type="number"
          min={min}
          max={max}
          step={step}
          value={value}
          onChange={handelr}
        ></input>
      </>
    )
  }
  return (
    <div className={styles.wrapper}>
      <canvas
        className={styles.canvas}
        width={WIDTH}
        height={height}
        ref={canvasRef}
      />
      <div className={styles.controls}>
        <label>
          frequency
          {numberInputs(10, 200, 0.1, frequency, handleFrequencyChange)}
        </label>
        <label>
          wave verocity
          {numberInputs(1000, 3000, 1, verocity, handleVelocityChange)}
        </label>
        <label>
          amplitude threshold
          {numberInputs(
            0.5,
            10,
            0.1,
            amplitudeThreshold,
            handleAmplitudeThresholdChange
          )}
        </label>
        <label>
          height
          {numberInputs(80, 200, 1, height, handleHeightChange)}
        </label>
        <label>
          wave source X{numberInputs(0, WIDTH, 1, sourceX, handleSourceXChange)}
        </label>
        <label>
          wave source Y
          {numberInputs(0, height, 1, sourceY, handleSourceYChange)}
        </label>
        <label>
          wave
          <input type='checkbox' checked={waveMode} onChange={handleWaveModeChange}/>
        </label>
      </div>
    </div>
  )
}
