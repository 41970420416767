import { Subject } from 'rxjs'
import { scan, throttleTime } from 'rxjs/operators'
import { LoopTimer } from '../../utils/timer'
import { mod } from './util'

class Cell {
  constructor(
    readonly i: number,
    readonly j: number,
    public points: ReadonlyArray<number>,
    public highLights: ReadonlyArray<number>,
    public active,
    public alive = false
  ) {}
}
type DataArrayType = ReadonlyArray<ReadonlyArray<Cell>>
export type Direction = 'horizontal' | 'vertical'
export type InitParams = {
  width?: number
  height?: number
  size?: number
  speed?: number
  rule?: number
  direction?: Direction
  imageData: ImageData
}
export interface PositionParam {
  x: number
  y: number
}
export type EmitValues = {
  imageBitmap?: ImageBitmap
  size?: number
  running?: boolean
  speed?: number
  rule?: number
  direction?: Direction
  imageData?: ImageData
}
export const DEFAULTS = {
  size: 10,
  rule: 30,
  speed: 15,
  direction: 'vertical' as Direction,
}
export const RULES = Array.from({ length: 256 }, (_v, i) => i)

const HIGHLIGHT_COLOR = { r: 200, g: 200, b: 250 }
const ALIVE_COLOR = { r: 100, g: 100, b: 100 }
const DEAD_COLOR = { r: 250, g: 250, b: 250 }

export class GameOfLifeLogic extends LoopTimer {
  private width = 10
  private height = 10
  private maxX = 1
  private maxY = 1
  private size = DEFAULTS.size
  private _speed = DEFAULTS.speed
  private rule = DEFAULTS.rule
  private direction = DEFAULTS.direction
  private dataArray: DataArrayType = []
  private imageData: ImageData | null = null

  private readonly _change$ = new Subject<EmitValues>()
  readonly change$ = this._change$.asObservable().pipe(
    scan((acc, value) => ({ ...acc, ...value }), {}),
    throttleTime(20)
  )

  private emitDataArrayChange() {
    this._change$.next({
      imageData: this.imageData,
      running: this.running,
    })
  }
  private emitSettingChange() {
    this._change$.next({
      size: this.size,
      speed: this.speed,
      rule: this.rule,
      direction: this.direction,
    })
  }

  private resizeDataArray() {
    this.maxX = Math.ceil(this.width / this.size)
    this.maxY = Math.ceil(this.height / this.size)
    const array = []
    for (let j = 0; j < this.maxY; j++) {
      const row = []
      const oldRow = this.dataArray[j] || []
      for (let i = 0; i < this.maxX; i++) {
        const cell = this.generateCell(i, j)
        if (oldRow[i]) cell.alive = oldRow[i].alive
        row.push(cell)
      }
      array.push(row)
    }
    this.dataArray = array
    this.updateImageDataAll()
    this.emitDataArrayChange()
  }
  private generateCell(i: number, j: number): Cell {
    const points = [],
      highLights = []
    const startX = this.size * i,
      startY = this.size * j,
      endX = Math.min(this.width, startX + this.size),
      endY = Math.min(this.height, startY + this.size),
      active =
        (this.direction === 'vertical' && j === 0) ||
        (this.direction === 'horizontal' && i === 0)
    for (let k = startX; k < endX; k++) {
      for (let l = startY; l < endY; l++) {
        if (
          active &&
          (k === startX || k === endX - 1 || l === startY || l === endY - 1)
        ) {
          highLights.push(k + l * this.width)
        } else {
          points.push(k + l * this.width)
        }
      }
    }
    return new Cell(i, j, points, highLights, active)
  }
  private updateImageDataAll() {
    for (const row of this.dataArray) {
      for (const cell of row) {
        this.updateImageDataCell(cell)
      }
    }
  }
  private updateImageDataCell(cell: Cell) {
    if (!this.imageData) {
      return
    }
    const color = cell.alive ? ALIVE_COLOR : DEAD_COLOR
    cell.points.map(p => {
      const pos = p * 4
      this.imageData.data[pos] = color.r
      this.imageData.data[pos + 1] = color.g
      this.imageData.data[pos + 2] = color.b
    })
    cell.highLights.map(p => {
      const pos = p * 4
      this.imageData.data[pos] = HIGHLIGHT_COLOR.r
      this.imageData.data[pos + 1] = HIGHLIGHT_COLOR.g
      this.imageData.data[pos + 2] = HIGHLIGHT_COLOR.b
    })
  }
  private updateDataArray() {
    const changes: Cell[] = []
    for (const row of this.dataArray) {
      for (const cell of row) {
        if (cell.active) {
          const neigbors = this.getNeighborCells(cell)
          const s =
            4 * (neigbors[0].alive ? 1 : 0) +
            2 * (cell.alive ? 1 : 0) +
            (neigbors[1].alive ? 1 : 0)
          if (((Math.pow(2, s) & this.rule) !== 0) !== cell.alive) {
            changes.push(cell)
          }
        } else {
          const copyCell =
            this.direction === 'vertical'
              ? this.dataArray[cell.j - 1][cell.i]
              : this.dataArray[cell.j][cell.i - 1]
          if (copyCell.alive !== cell.alive) {
            changes.push(cell)
          }
        }
      }
    }
    changes.forEach(cell => {
      cell.alive = !cell.alive
      this.updateImageDataCell(cell)
    })
  }
  private getNeighborCells(cell: Cell): [Cell, Cell] {
    return this.direction === 'vertical'
      ? [
          this.dataArray[cell.j][mod(cell.i - 1, this.maxX)],
          this.dataArray[cell.j][mod(cell.i + 1, this.maxX)],
        ]
      : [
          this.dataArray[mod(cell.j - 1, this.maxY)][cell.i],
          this.dataArray[mod(cell.j + 1, this.maxY)][cell.i],
        ]
  }

  private getCellByPosition({ x, y }: PositionParam): Cell | null {
    if (x < 0 || this.width <= x) return null
    if (y < 0 || this.height <= y) return null
    const i = Math.floor(x / this.size),
      j = Math.floor(y / this.size)
    return this.dataArray[j][i]
  }

  // actions
  init({
    width = this.width,
    height = this.height,
    size = this.size,
    rule = this.rule,
    speed = this.speed,
    direction = this.direction,
    imageData,
  }: InitParams) {
    this.width = width
    this.height = height
    this.size = size
    this.rule = rule
    this.speed = speed
    this.direction = direction
    this.imageData = imageData
    this.resizeDataArray()
  }
  click({ x, y, toggle = true }) {
    const cell = this.getCellByPosition({ x, y })
    if (!cell) return
    if (!toggle && cell.alive) return
    if (!cell.active) return
    cell.alive = !cell.alive
    this.updateImageDataCell(cell)
    this.emitDataArrayChange()
  }
  settingChange({ target, value }: any) {
    if (target === 'speed') {
      this.speed = value
    } else if (target === 'rule') {
      this.rule = value
    } else if (target === 'direction') {
      this.direction = value
      this.resizeDataArray()
    } else if (target === 'size') {
      this.size = value
      this.resizeDataArray()
    }
    this.emitSettingChange()
  }
  resizeCanvas({ width, height, imageData }) {
    this.width = width
    this.height = height
    this.imageData = imageData
    this.resizeDataArray()
  }
  reset() {
    this.dataArray = []
    this.resizeDataArray()
  }

  // loopTimer
  protected task() {
    this.updateDataArray()
  }
  protected completed() {
    this.emitDataArrayChange()
  }
  get speed() {
    return this._speed
  }
  set speed(val) {
    this._speed = val
    this.waitInterval = 1000 / val
  }
}

export default GameOfLifeLogic
