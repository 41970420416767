import React, { Component } from 'react'
import ResizeObserver from 'resize-observer-polyfill'

import { DEFAULTS, Range } from './MandelbrotLogic'
const styles = require('./index.module.scss')

export type MandelbrotProps = {
  control?: string
  targetRange?: Range
}
type MandelbrotState = {
  ctx: CanvasRenderingContext2D | null
  worker: Worker | null
  controlBoxOpen: boolean
  dragingInfo: DragingInfo | null
  observer: ResizeObserver | null
  targetRange: Range
  displayingRange: Range | null
  maxIteration: number
}
type DragingInfo = {
  top: number
  left: number
  width: number
  height: number
  startLeft: number
  startTop: number
}

class Mandelbrot extends Component<MandelbrotProps, MandelbrotState> {
  constructor(props: MandelbrotProps) {
    super(props)
    this.canvasRef = React.createRef()
    this.rootRef = React.createRef()
    this.handleCanvasPointerDown = this.handleCanvasPointerDown.bind(this)
    this.handleCanvasPointerMove = this.handleCanvasPointerMove.bind(this)
    this.handleCanvasPointerUp = this.handleCanvasPointerUp.bind(this)
    this.reset = this.reset.bind(this)
    this.settingChange = this.settingChange.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.state = {
      ctx: null,
      worker: null,
      controlBoxOpen: false,
      dragingInfo: null,
      observer: null,
      displayingRange: null,
      targetRange: DEFAULTS.targetRange,
      maxIteration: DEFAULTS.maxIteration,
      ...props,
    }
  }
  private readonly canvasRef: React.RefObject<HTMLCanvasElement>
  private readonly rootRef: React.RefObject<HTMLDivElement>
  componentDidMount() {
    const canvasContext = this.canvasRef.current.getContext('2d')
    const expandCanvasAndGetImageData = () => {
      const width = this.rootRef.current.clientWidth,
        height = this.rootRef.current.clientHeight
      this.canvasRef.current.width = width
      this.canvasRef.current.height = height
      canvasContext.fillStyle = 'white'
      canvasContext.fillRect(0, 0, width, height)
      const imageData = canvasContext.getImageData(0, 0, width, height)
      return { width, height, imageData }
    }
    const worker = new Worker(new URL('./Mandelbrot.worker.ts', import.meta.url))
    worker.postMessage({
      action: 'init',
      param: {
        targetRange: this.state.targetRange,
        ...expandCanvasAndGetImageData(),
      },
    })
    const observer = new ResizeObserver(() => {
      worker.postMessage({
        action: 'resizeCanvas',
        param: expandCanvasAndGetImageData(),
      })
    })
    observer.observe(this.rootRef.current)
    this.setState({ observer })
    worker.addEventListener('message', e => {
      if ('imageData' in e.data) {
        canvasContext.putImageData(e.data.imageData, 0, 0)
      }
      if ('targetRange' in e.data) {
        this.setState({ targetRange: e.data.targetRange })
      }
      if ('displayingRange' in e.data) {
        this.setState({ displayingRange: e.data.displayingRange })
      }
      if ('maxIteration' in e.data) {
        this.setState({ maxIteration: e.data.maxIteration })
      }
    })
    this.setState({ worker })
  }
  componentWillUnmount() {
    if (this.state.observer) this.state.observer.disconnect()
    this.state.worker?.terminate()
  }
  handleCanvasPointerDown(event: React.PointerEvent) {
    const dragingInfo = this.generateDragingInfo(event)
    this.setState({ dragingInfo })
    this.canvasRef.current.setPointerCapture(event.pointerId)
  }
  handleCanvasPointerMove(event: React.PointerEvent) {
    if (!this.state.dragingInfo) return
    const dragingInfo = this.generateDragingInfo(event)
    this.setState({ dragingInfo })
  }
  handleCanvasPointerUp(event: React.PointerEvent) {
    if (!this.state.dragingInfo) return
    if (this.haveEnoughDraggingDistance()) {
      const { left, width, top, height } = this.generateDragingInfo(event),
        xR =
          (this.state.displayingRange.x2 - this.state.displayingRange.x1) /
          this.rootRef.current.clientWidth,
        yR =
          (this.state.displayingRange.y2 - this.state.displayingRange.y1) /
          this.rootRef.current.clientHeight,
        x1 = this.state.displayingRange.x1 + left * xR,
        x2 = this.state.displayingRange.x1 + (left + width) * xR,
        y1 = this.state.displayingRange.y1 + top * yR,
        y2 = this.state.displayingRange.y1 + (top + height) * yR
      const targetRange = { x1, x2, y1, y2 }
      this.state.worker.postMessage({
        action: 'rangeChange',
        param: targetRange,
      })
    }
    this.setState({ dragingInfo: null })
    this.canvasRef.current.releasePointerCapture(event.pointerId)
  }
  generateDragingInfo(event: React.PointerEvent) {
    const rect = this.canvasRef.current.getBoundingClientRect(),
      left = event.clientX - rect.left,
      top = event.clientY - rect.top
    return this.state.dragingInfo
      ? {
          ...this.state.dragingInfo,
          left: Math.min(this.state.dragingInfo.startLeft, left),
          top: Math.min(this.state.dragingInfo.startTop, top),
          width: Math.abs(this.state.dragingInfo.startLeft - left),
          height: Math.abs(this.state.dragingInfo.startTop - top),
        }
      : {
          startLeft: left,
          startTop: top,
          left,
          top,
          width: 0,
          height: 0,
        }
  }
  haveEnoughDraggingDistance() {
    if (!this.state.dragingInfo) return false
    const dragedDistance =
      Math.pow(this.state.dragingInfo.width, 2) +
      Math.pow(this.state.dragingInfo.height, 2)
    return dragedDistance >= 500
  }
  reset() {
    this.state.worker.postMessage({
      action: 'rangeChange',
      param: DEFAULTS.targetRange,
    })
  }
  settingChange(event: React.ChangeEvent) {
    const chagedEl = event.target as HTMLInputElement
    const target = chagedEl.dataset.target
    const value = Number(chagedEl.value)
    this.state.worker.postMessage({
      action: 'settingChange',
      param: { target, value },
    })
  }
  handleKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'ArrowUp') {
      this.move('up')
    } else if (event.key === 'ArrowDown') {
      this.move('down')
    } else if (event.key === 'ArrowLeft') {
      this.move('left')
    } else if (event.key === 'ArrowRight') {
      this.move('right')
    } else if (event.key === '+') {
      this.zoom('in')
    } else if (event.key === '-') {
      this.zoom('out')
    }
  }
  move(direction: 'up' | 'down' | 'left' | 'right') {
    const dRange = this.state.displayingRange
    const d = [0, 0]
    if (direction === 'up') {
      d[1] = (dRange.y1 - dRange.y2) / 4
    } else if (direction === 'down') {
      d[1] = (dRange.y2 - dRange.y1) / 4
    } else if (direction === 'left') {
      d[0] = (dRange.x1 - dRange.x2) / 4
    } else if (direction === 'right') {
      d[0] = (dRange.x2 - dRange.x1) / 4
    }
    this.state.worker.postMessage({
      action: 'rangeChange',
      param: {
        x1: dRange.x1 + d[0],
        x2: dRange.x2 + d[0],
        y1: dRange.y1 + d[1],
        y2: dRange.y2 + d[1],
      },
    })
  }
  zoom(direction: 'in' | 'out') {
    const dRange = this.state.displayingRange,
      dx = ((dRange.x2 - dRange.x1) / 4) * (direction === 'in' ? 1 : -1),
      dy = ((dRange.y2 - dRange.y1) / 4) * (direction === 'in' ? 1 : -1)
    this.state.worker.postMessage({
      action: 'rangeChange',
      param: {
        x1: dRange.x1 + dx,
        x2: dRange.x2 - dx,
        y1: dRange.y1 + dy,
        y2: dRange.y2 - dy,
      },
    })
  }
  render() {
    const controlButtons = (
      <div className={styles.controlButtons}>
        <div onClick={() => this.zoom('in')} className={styles.controlButton}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <polygon points="8,0 12,0 12,8 20,8 20,12 12,12 12,20 8,20 8,12 0,12 0,8 8,8" />
          </svg>
        </div>
        <div onClick={() => this.zoom('out')} className={styles.controlButton}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <polygon points="0,8 20,8 20,12 0,12" />
          </svg>
        </div>
        <div onClick={() => this.move('left')} className={styles.controlButton}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <polygon points="0,10 8,2 8,8 20,8 20,12 8,12 8,18" />
          </svg>
        </div>
        <div onClick={() => this.move('up')} className={styles.controlButton}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <polygon points="10,0 18,8 12,8 12,20 8,20 8,8 2,8" />
          </svg>
        </div>
        <div onClick={() => this.move('down')} className={styles.controlButton}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <polygon points="10,20 2,12 8,12 8,0 12,0 12,12 18,12" />
          </svg>
        </div>
        <div
          onClick={() => this.move('right')}
          className={styles.controlButton}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <polygon points="20,10 12,18 12,12 0,12 0,8 12,8 12,2" />
          </svg>
        </div>
        <div onClick={this.reset} className={styles.controlButton}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="-50 -50 100 100">
            <path
              d="M 34.6 20 L 45.8 20
                  A 50 50 0 1 1 35.6 -35.6
                  L 48 -48 L 48 -5 L 5 -5 L 28.3 -28.3
                  A 40 40 0 1 0 38.7 10
                "
            />
          </svg>
        </div>
        {this.props.control === 'simple' ? null : (
          <div
            onClick={() =>
              this.setState({ controlBoxOpen: !this.state.controlBoxOpen })
            }
            className={styles.controlButton}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
              <circle cx="15" cy="4" r="4" />
              <circle cx="15" cy="15" r="4" />
              <circle cx="15" cy="26" r="4" />
            </svg>
          </div>
        )}
      </div>
    )
    const control =
      this.props.control === 'simple' ? (
        <div className={styles.control}>{controlButtons}</div>
      ) : (
        <div className={styles.control}>
          {controlButtons}
          {this.state.controlBoxOpen ? (
            <div className={styles.controlBox}>
              <div>displaying</div>
              {this.state.displayingRange ? (
                <div className={styles.rangeBox}>
                  <div className={styles.nowrap + ' ' + styles.boxItem}>
                    {this.state.displayingRange.y1}
                  </div>
                  <div
                    className={styles.nowrap}
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div className={styles.boxItem}>
                      {this.state.displayingRange.x1}
                    </div>
                    <div className={styles.boxItem}>
                      {this.state.displayingRange.x2}
                    </div>
                  </div>
                  <div className={styles.nowrap + ' ' + styles.boxItem}>
                    {this.state.displayingRange.y2}
                  </div>
                </div>
              ) : null}
              <label>max iteration :</label>
              <input
                type="number"
                step="1000"
                max="30000"
                min="100"
                onChange={this.settingChange}
                value={this.state.maxIteration}
                data-target="max-iteration"
              />
            </div>
          ) : null}
        </div>
      )
    return (
      <div
        ref={this.rootRef}
        className={styles.root}
        onKeyDown={this.handleKeyDown}
        tabIndex={-1}
      >
        {this.haveEnoughDraggingDistance() ? (
          <div
            className={styles.draggingOverlay}
            style={this.state.dragingInfo}
          ></div>
        ) : null}
        {control}
        <canvas
          className={styles.canvas}
          onPointerDown={this.handleCanvasPointerDown}
          onPointerMove={this.handleCanvasPointerMove}
          onPointerUp={this.handleCanvasPointerUp}
          ref={this.canvasRef}
        />
      </div>
    )
  }
}

export default Mandelbrot
