import { Subject } from 'rxjs'
import { getColorArray } from './colors'

export type EmitValues = {
  particles?: SerializedParticle[]
  numColors?: number
  repulsiveRadius?: number
  interactRadius?: number
  friction?: number
  speed?: number
  matrix?: Matrix
  boundary?: Boundary
  gravity?: number
}
type SerializedParticle = [number, number, string]

type InitParams = {
  width: number
  height: number
  numParticles: number
  numColors?: number
  repulsiveRadius?: number
  interactRadius?: number
  friction?: number
  speed?: number
  matrix?: Matrix
  boundary?: Boundary
  gravity?: number
}

export const DEFAULT_VALUES = {
  numParticles: 2000,
  numColors: 8,
  repulsiveRadius: 25,
  interactRadius: 50,
  friction: 5,
  speed: 1,
  boundary: 'loop',
  gravity: 20,
} as const

class Particle {
  constructor(
    public x: number,
    public y: number,
    public colorIndex: number,
    public vx: number,
    public vy: number
  ) {}
}

class Container {
  constructor(public particles: Particle[], public neighbors: Container[]) {}
}

const MAX_NUM_COLORS = 12 as const

export const COLORS_BY_NUM_COLORS = Array.from({ length: MAX_NUM_COLORS }).map(
  (_, i) => getColorArray(i + 1)
)

export type Matrix = number[][] // TODO tupleにする？

const generateRandomMatrix: () => Matrix = () =>
  Array.from({ length: MAX_NUM_COLORS }, () =>
    Array.from(
      { length: MAX_NUM_COLORS },
      () => Math.round((1 - 2 * Math.random()) * 10) / 10
    )
  )

export const BOUNDARIES = ['loop', 'bounce'] as const
export type Boundary = typeof BOUNDARIES[number]


// prettier-ignore
const NEIGHBORS = [
  [-2, -2], [-1, -2], [0, -2], [1, -2], [2, -2],
  [-2, -1], [-1, -1], [0, -1], [1, -1], [2, -1],
  [-2,  0], [-1,  0], [0,  0], [1,  0], [2,  0],
  [-2,  1], [-1,  1], [0,  1], [1,  1], [2,  1],
  [-2,  2], [-1,  2], [0,  2], [1,  2], [2,  2],
] as const

const wrap = (v: number, max: number) => {
  if (max <= v) {
    return v - max
  } else if (v < 0) {
    return v + max
  } else {
    return v
  }
}

export class ParticleLifeLogic {
  private width = 800
  private height = 600
  private particles: Particle[] = []
  private containers: Container[][] = []
  private loopStarted = false
  private matrix: Matrix = generateRandomMatrix()
  private repulsiveRadius: number = DEFAULT_VALUES.repulsiveRadius
  private interactRadius: number = DEFAULT_VALUES.interactRadius
  private numColors: number = DEFAULT_VALUES.numColors
  private speed: number = DEFAULT_VALUES.speed
  private friction: number = DEFAULT_VALUES.friction
  private boundary: Boundary = DEFAULT_VALUES.boundary
  private gravity: number = DEFAULT_VALUES.gravity

  private readonly _change$ = new Subject<EmitValues>()
  readonly change$ = this._change$.asObservable()

  private emitChange() {
    this._change$.next({
      particles: this.particles.map(p => [
        p.x,
        p.y,
        COLORS_BY_NUM_COLORS[this.numColors - 1][p.colorIndex],
      ]),
      numColors: this.numColors,
      repulsiveRadius: this.repulsiveRadius,
      interactRadius: this.interactRadius,
      friction: this.friction,
      speed: this.speed,
      boundary: this.boundary,
      gravity: this.gravity,
    })
  }
  private emitMatrix() {
    this._change$.next({ matrix: this.matrix })
  }
  private getDistanceAndDirection(p: Particle, other: Particle) {
    let dx = other.x - p.x,
      dy = other.y - p.y
    const halfX = this.width / 2,
      halfY = this.height / 2
    if (dx < -halfX) {
      dx = this.width + dx
    } else if (halfX < dx) {
      dx = dx - this.width
    }
    if (dy < -halfY) {
      dy = this.height + dy
    } else if (halfY < dy) {
      dy = dy - this.height
    }
    return [Math.sqrt(dx * dx + dy * dy), dx, dy]
  }
  private initializeContainers() {
    const containers: Container[][] = []
    const cxn = Math.floor(this.width / this.interactRadius / 2)
    const cyn = Math.floor(this.height / this.interactRadius / 2)
    for (let i = 0; i < cxn; i++) {
      const c = []
      for (let j = 0; j < cyn; j++) {
        c.push(new Container([], []))
      }
      containers.push(c)
    }
    for (let i = 0; i < containers.length; i++) {
      const cs = containers[i]
      for (let j = 0; j < cs.length; j++) {
        for (let [di, dj] of NEIGHBORS) {
          if (this.boundary === 'loop') {
            const ni = wrap(i + di, cxn), nj = wrap(j + dj, cyn)
            cs[j].neighbors.push(containers[ni][nj])
          } else {
            const ni = i + di, nj = j + dj
            if (ni < 0 || ni >= cxn || nj < 0 || nj >= cyn) continue
            cs[j].neighbors.push(containers[ni][nj])
          }
        }
      }
    }
    this.containers = containers
  }

  private populateContainers() {
    for (const cs of this.containers) {
      for (const c of cs) {
        c.particles.splice(0, c.particles.length)
      }
    }
    const cxn = this.containers.length
    const cyn = this.containers[0].length
    const cx = this.width / cxn
    const cy = this.height / cyn
    for (const p of this.particles) {
      const ci = Math.floor(p.x / cx)
      const cj = Math.floor(p.y / cy)
      if (this.containers[ci]) this.containers[ci][cj]?.particles.push(p)
    }
  }
  private updateVelocities(dt: number) {
    for (const cs of this.containers) {
      for (const c of cs) {
        for (const p of c.particles) {
          let fx = 0,
            fy = 0
          for (const nc of c.neighbors) {
            for (const other of nc.particles) {
              if (p === other) continue
              const [d, dx, dy] = this.getDistanceAndDirection(p, other)
              if (d === 0 || this.interactRadius < d) continue
              const c =
                d < this.repulsiveRadius
                  ? d - this.repulsiveRadius
                  : this.matrix[p.colorIndex][other.colorIndex] *
                    (this.interactRadius -
                      this.repulsiveRadius -
                      Math.abs(
                        this.repulsiveRadius + this.interactRadius - 2 * d
                      ))
              const f = c / d
              fx += f * dx
              fy += f * dy
            }
          }
          const fr = (100 - this.friction) / 100 // 本当はdtもかかわってくるはず?
          p.vx *= fr
          p.vy *= fr
          if (this.boundary === 'bounce') {
            if (p.x < this.repulsiveRadius)
              fx += this.repulsiveRadius - p.x
            if (this.width - p.x < this.repulsiveRadius)
              fx -= this.repulsiveRadius - this.width + p.x
            if (p.y < this.repulsiveRadius)
              fy += this.repulsiveRadius - p.y
            if (this.height - p.y < this.repulsiveRadius)
              fy -= this.repulsiveRadius - this.height + p.y
          }
          if (this.gravity) {
            fx += (0.5 - p.x / this.width) * this.gravity
            fy += (0.5 - p.y / this.height) * this.gravity
          }
          p.vx += fx * dt
          p.vy += fy * dt
        }
      }
    }
  }
  private updatePositions(dt: number) {
    for (const p of this.particles) {
      p.x = p.x + p.vx * dt * this.speed
      p.y = p.y + p.vy * dt * this.speed
      if (this.boundary === 'loop') {
        p.x = wrap(p.x, this.width)
        p.y = wrap(p.y, this.height)
      } else {
        if (p.x < 0) {
          p.vx = -1 * p.vx
          p.x = 0
        } else if (this.width <= p.x) {
          p.vx = -1 * p.vx
          p.x = this.width - 1
        }
        if (p.y < 0) {
          p.vy = -1 * p.vy
          p.y = 0
        } else if (this.height <= p.y) {
          p.vy = -1 * p.vy
          p.y = this.height - 1
        }
      }
    }
  }

  private loop() {
    this.loopStarted = true
    const dt = 30
    const tsBefore = Date.now()
    //const perfArray = Math.random() < 0.05 ? [] : null

    this.populateContainers()
    //if (perfArray) perfArray.push(Date.now() - tsBefore)
    this.updateVelocities(dt / 1000)
    //if (perfArray) perfArray.push(Date.now() - tsBefore)
    this.updatePositions(dt / 1000)
    //if (perfArray) perfArray.push(Date.now() - tsBefore)
    this.emitChange()
    //if (perfArray) perfArray.push(Date.now() - tsBefore)
    const wait = Math.max(0, dt - (Date.now() - tsBefore))
    // if (perfArray) perfArray.push(Date.now() - tsBefore)
    // if (perfArray) console.log(perfArray)
    setTimeout(() => this.loop(), wait)
  }

  // actions
  init(param: InitParams) {
    if (param.width === 0 || param.height === 0) return
    this.resize({ width: param.width, height: param.height })
    if (param.numColors) this.updateNumColors(param.numColors)
    this.updateNumParticles(param.numParticles)
    if (param.repulsiveRadius) this.updateRepulsiveRadius(param.repulsiveRadius)
    if (param.interactRadius) this.updateInteractRadius(param.interactRadius)
    if (param.speed) this.updateSpeed(param.speed)
    if (param.friction != null) this.updateFriction(param.friction)
    if (param.gravity != null) this.updateGravity(param.gravity)
    if (param.matrix) this.matrix = param.matrix
    if (!this.loopStarted) {
      this.loop()
    }
    this.emitMatrix()
  }
  resize({ width, height }) {
    if (width === 0 || height === 0) return
    if (width < this.width || height < this.height) {
      for (const p of this.particles) {
        if (p.x > width) p.x = width - 10 * Math.random()
        if (p.y > height) p.y = height - 10 * Math.random()
      }
    }
    this.width = width
    this.height = height
    this.initializeContainers()
  }
  updateNumParticles(numParticles: number) {
    if (this.particles.length < numParticles) {
      for (let i = this.particles.length; i < numParticles; i++) {
        this.particles.push(
          new Particle(
            Math.random() * this.width,
            Math.random() * this.height,
            Math.floor(Math.random() * this.numColors),
            0,
            0
          )
        )
      }
    } else if (this.particles.length > numParticles) {
      this.particles.splice(numParticles, this.particles.length - numParticles)
    }
  }
  updateNumColors(numColors: number) {
    if (numColors > MAX_NUM_COLORS) return
    if (numColors <= this.numColors) {
      for (const p of this.particles) {
        if (numColors <= p.colorIndex) {
          p.colorIndex = Math.floor(Math.random() * numColors)
        }
      }
    } else if (this.numColors < numColors) {
      const pChange = 1 - this.numColors / numColors
      for (const p of this.particles) {
        if (Math.random() < pChange) {
          p.colorIndex =
            this.numColors +
            Math.floor(Math.random() * (numColors - this.numColors))
        }
      }
    }
    this.numColors = numColors
  }
  updateFriction(friction: number) {
    this.friction = friction
  }
  updateRepulsiveRadius(r: number) {
    if (this.interactRadius <= r) return
    this.repulsiveRadius = r
  }
  updateInteractRadius(r: number) {
    if (r <= this.repulsiveRadius) return
    this.interactRadius = r
    this.initializeContainers()
  }
  updateSpeed(speed: number) {
    this.speed = speed
  }
  updateMatrix({ i, j, value }: { i: number; j: number; value: number }) {
    if (i >= MAX_NUM_COLORS || j >= MAX_NUM_COLORS) return
    this.matrix[i][j] = value
    this.emitMatrix()
  }
  updateBoundary(boundary: Boundary) {
    this.boundary = boundary
    this.initializeContainers()
  }
  updateGravity(gravity: number) {
    this.gravity = gravity
  }
  randomizePosition() {
    this.particles = Array.from(
      { length: this.particles.length },
      () =>
        new Particle(
          Math.random() * this.width,
          Math.random() * this.height,
          Math.floor(Math.random() * this.numColors),
          0,
          0
        )
    )
  }
  randomizeMatrix() {
    this.matrix = generateRandomMatrix()
    this.emitMatrix()
  }
}

export default ParticleLifeLogic
