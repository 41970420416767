import React, { Component } from 'react'
import ResizeObserver from 'resize-observer-polyfill'

import { DEFAULTS, RULES } from './OneDimensionCALogic'
const styles = require('./index.module.scss')

export type OneDimensionCAProps = {
  size?: number
  speed?: number
  rule?: number
  control?: string
  direction?: string
}
type OneDimensionCAState = {
  ctx: CanvasRenderingContext2D | null
  playing: boolean
  worker: Worker | null
  controlBoxOpen: boolean
  size: number
  speed: number
  rule: number
  direction: string
  draging: boolean
  observer: ResizeObserver | null
}

class GameOfLife extends Component<OneDimensionCAProps, OneDimensionCAState> {
  constructor(props: OneDimensionCAProps) {
    super(props)
    this.canvasRef = React.createRef()
    this.rootRef = React.createRef()
    this.handleCanvasPointerDown = this.handleCanvasPointerDown.bind(this)
    this.handleCanvasPointerMove = this.handleCanvasPointerMove.bind(this)
    this.handleCanvasPointerUp = this.handleCanvasPointerUp.bind(this)
    this.togglePlaying = this.togglePlaying.bind(this)
    this.reset = this.reset.bind(this)
    this.step = this.step.bind(this)
    this.settingChange = this.settingChange.bind(this)
    this.state = {
      ctx: null,
      playing: false,
      worker: null,
      controlBoxOpen: false,
      draging: false,
      observer: null,
      ...DEFAULTS,
      ...props,
    }
  }
  private readonly canvasRef: React.RefObject<HTMLCanvasElement>
  private readonly rootRef: React.RefObject<HTMLDivElement>
  componentDidMount() {
    const canvasContext = this.canvasRef.current.getContext('2d')
    const expandCanvasAndGetImageData = () => {
      const width = this.rootRef.current.clientWidth,
        height = this.rootRef.current.clientHeight
      this.canvasRef.current.width = width
      this.canvasRef.current.height = height
      canvasContext.fillStyle = 'white'
      canvasContext.fillRect(0, 0, width, height)
      const imageData = canvasContext.getImageData(0, 0, width, height)
      return { width, height, imageData }
    }
    const worker = new Worker(new URL('./OneDimensionCA.worker.ts', import.meta.url))
    worker.postMessage({
      action: 'init',
      param: {
        rule: this.state.rule,
        speed: this.state.speed,
        ...expandCanvasAndGetImageData(),
      },
    })
    const observer = new ResizeObserver(() => {
      worker.postMessage({
        action: 'resizeCanvas',
        param: expandCanvasAndGetImageData(),
      })
    })
    observer.observe(this.rootRef.current)
    this.setState({ observer })
    worker.addEventListener('message', e => {
      if ('imageData' in e.data) {
        canvasContext.putImageData(e.data.imageData, 0, 0)
      }
      if ('size' in e.data) this.setState({ size: e.data.size })
      if ('speed' in e.data) this.setState({ speed: e.data.speed })
      if ('rule' in e.data) this.setState({ rule: e.data.rule })
      if ('direction' in e.data) this.setState({ direction: e.data.direction })
      if ('running' in e.data) this.setState({ playing: e.data.running })
    })
    this.setState({ worker })
  }
  componentWillUnmount() {
    if (this.state.observer) this.state.observer.disconnect()
    if (this.state.playing) this.state.worker.postMessage({ action: 'stop' })
    this.state.worker?.terminate()
  }
  handleCanvasPointerDown(event: React.PointerEvent) {
    this.setState({ draging: true })
    this.canvasRef.current.setPointerCapture(event.pointerId)
    this.postClickMessage(event, true)
  }
  handleCanvasPointerMove(event: React.PointerEvent) {
    if (!this.state.draging) return
    this.postClickMessage(event, false)
  }
  handleCanvasPointerUp(event: React.PointerEvent) {
    this.setState({ draging: false })
    this.canvasRef.current.releasePointerCapture(event.pointerId)
  }
  postClickMessage(event: React.PointerEvent, toggle: boolean) {
    if (!this.state.worker) return
    const rect = this.canvasRef.current.getBoundingClientRect()
    this.state.worker.postMessage({
      action: 'click',
      param: {
        x: event.clientX - rect.left,
        y: event.clientY - rect.top,
        toggle,
      },
    })
  }

  togglePlaying() {
    if (!this.state.worker) return
    if (this.state.playing) {
      this.state.worker.postMessage({ action: 'stop' })
    } else {
      this.state.worker.postMessage({ action: 'start' })
    }
  }
  step() {
    this.state.worker.postMessage({ action: 'step' })
  }
  reset() {
    this.state.worker.postMessage({ action: 'reset' })
  }
  settingChange(event: React.ChangeEvent) {
    const chagedEl = event.target as HTMLInputElement
    const target = chagedEl.dataset.target
    let value: string | number[] | number = chagedEl.value
    if (target === 'spawn' || target === 'survive') {
      if (!/^\d+$/.test(value)) return
      value = value.split('').map(Number)
    } else if (target === 'speed' || target === 'size') {
      value = Number(value)
      if (!value) return
    }
    this.state.worker.postMessage({
      action: 'settingChange',
      param: { target, value },
    })
  }
  render() {
    const controlButtons = (
      <div className={styles.controlButtons}>
        <div onClick={this.togglePlaying} className={styles.controlButton}>
          {this.state.playing ? (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <rect x="3" y="0" width="5" height="20" />
              <rect x="13" y="0" width="5" height="20" />
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <polygon points="0,0 20,10 0,20" />
            </svg>
          )}
        </div>
        <div onClick={this.step} className={styles.controlButton}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <polygon points="0,0 16,10 0,20" />
            <rect x="16" y="0" width="4" height="20" />
          </svg>
        </div>
        <div onClick={this.reset} className={styles.controlButton}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="-50 -50 100 100">
            <path
              d="M 34.6 20 L 45.8 20
                  A 50 50 0 1 1 35.6 -35.6
                  L 48 -48 L 48 -5 L 5 -5 L 28.3 -28.3
                  A 40 40 0 1 0 38.7 10
                "
            />
          </svg>
        </div>
        {this.props.control === 'simple' ? null : (
          <div
            onClick={() =>
              this.setState({ controlBoxOpen: !this.state.controlBoxOpen })
            }
            className={styles.controlButton}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
              <circle cx="15" cy="4" r="4" />
              <circle cx="15" cy="15" r="4" />
              <circle cx="15" cy="26" r="4" />
            </svg>
          </div>
        )}
      </div>
    )
    const control =
      this.props.control === 'simple' ? (
        <div className={styles.control}>{controlButtons}</div>
      ) : (
        <div className={styles.control}>
          {controlButtons}
          {this.state.controlBoxOpen ? (
            <div className={styles.controlBox}>
              <label>size: </label>
              <input
                type="number"
                max="200"
                min="3"
                onChange={this.settingChange}
                value={this.state.size}
                data-target="size"
              />
              <label>speed: </label>
              <input
                type="range"
                max="30"
                min="1"
                onChange={this.settingChange}
                value={this.state.speed}
                data-target="speed"
              />
              <label>rule: </label>
              <select
                onChange={this.settingChange}
                value={this.state.rule}
                data-target="rule"
              >
                {RULES.map(n => (
                  <option value={String(n)} key={n}>
                    {n}
                  </option>
                ))}
              </select>
              <label>direction: </label>
              <select
                onChange={this.settingChange}
                value={this.state.direction}
                data-target="direction"
              >
                <option value="vertical">vertical</option>
                <option value="horizontal">horizontal</option>
              </select>
            </div>
          ) : null}
        </div>
      )
    return (
      <div ref={this.rootRef} className={styles.root}>
        {control}
        <canvas
          className={styles.canvas}
          onPointerDown={this.handleCanvasPointerDown}
          onPointerMove={this.handleCanvasPointerMove}
          onPointerUp={this.handleCanvasPointerUp}
          ref={this.canvasRef}
        />
      </div>
    )
  }
}

export default GameOfLife
