// extracted by mini-css-extract-plugin
export var boxItem = "index-module--boxItem--+YaUb";
export var canvas = "index-module--canvas--WCY6r";
export var control = "index-module--control--6HZTM";
export var controlBox = "index-module--control-box--AyQdf";
export var controlButton = "index-module--control-button--r2ALw";
export var controlButtons = "index-module--control-buttons--OSMhv";
export var disabled = "index-module--disabled--Vo3fh";
export var draggingOverlay = "index-module--draggingOverlay--AFKEc";
export var nowrap = "index-module--nowrap--mEEw7";
export var rangeBox = "index-module--rangeBox--9c411";
export var root = "index-module--root--cCarO";