function getColor(H: number) {
  const Hp = H * 6,
    X = 1 - Math.abs((Hp % 2) - 1)
  let R, G, B
  if (Hp < 1) [R, G, B] = [1, X, 0]
  else if (Hp < 2) [R, G, B] = [X, 1, 0]
  else if (Hp < 3) [R, G, B] = [0, 1, X]
  else if (Hp < 4) [R, G, B] = [0, X, 1]
  else if (Hp < 5) [R, G, B] = [X, 0, 1]
  else [R, G, B] = [1, 0, X]
  const r = Math.floor(R * 255),
    g = Math.floor(G * 255),
    b = Math.floor(B * 255)
  return `rgb(${r}, ${g}, ${b})`
}

export const getColorArray = (numColors: number) =>
  Array.from({ length: numColors }).map((_, i) => getColor(i / numColors))
