import React, { useEffect, useRef, useState, useCallback } from 'react'
import ResizeObserver from 'resize-observer-polyfill'
import { Subject, debounceTime } from 'rxjs'
import { FaCog } from 'react-icons/fa';

import * as styles from './index.module.scss'
import { type EmitValues, type Matrix , DEFAULT_VALUES, BOUNDARIES, type Boundary } from './ParticleLifeLogic'
import RuleMatrixTable, { RuleMatrixChangeParam } from './RuleMatrixTable'

type ParticleElement = HTMLDivElement

const PARTICLE_SIZES = ['small', 'medium', 'large'] as const
type ParticleSize = typeof PARTICLE_SIZES[number]

const applyParticleStyle = (
  el: ParticleElement,
  particleSize: ParticleSize
) => {
  if (particleSize === 'small') {
    el.innerText = '.'
    el.style.fontSize = '16px'
    el.style.top = '-15px'
    el.style.left = '-2px'
  } else if (particleSize === 'medium') {
    el.innerText = '.'
    el.style.fontSize = '32px'
    el.style.top = '-30px'
    el.style.left = '-5px'
  } else {
    el.innerText = '●'
    el.style.fontSize = '10px'
    el.style.top = '-5px'
    el.style.left = '-5px'
  }
}

interface ParticleLifeProps {
  showControl?: boolean
  backgroundColor?: string
  particleSize?: ParticleSize
  numParticles?: number
  numColors?: number
  repulsiveRadius?: number
  interactRadius?: number
  friction?: number
  speed?: number
  boundary?: Boundary
  gravity?: number
}

export default function ParticleLife(props: ParticleLifeProps) {
  const fieldRef = useRef<HTMLDivElement>(null)
  const particlesRef = useRef<Array<ParticleElement>>([])
  const workerRef = useRef<Worker>(null)
  const [matrix, setMatrix] = useState<Matrix | null>(null)
  const [backgroundColor, setBackgroundColor] = useState<string>(
    props.backgroundColor || '#111112'
  )
  const [particleSize, setParticleSize] = useState<ParticleSize>(
    props.particleSize || 'medium'
  )
  const [numParticles, setNumParticles] = useState<number>(
    props.numParticles || DEFAULT_VALUES.numParticles
  )
  const [numColors, setNumColors] = useState<number>(
    props.numColors || DEFAULT_VALUES.numColors
  )
  const [repulsiveRadius, setRepulsiveRadius] = useState<number>(
    props.repulsiveRadius || DEFAULT_VALUES.repulsiveRadius
  )
  const [interactRadius, setInteractRadius] = useState<number>(
    props.interactRadius || DEFAULT_VALUES.interactRadius
  )
  const [friction, setFriction] = useState<number>(
    props.friction ?? DEFAULT_VALUES.friction
  )
  const [speed, setSpeed] = useState<number>(
    props.speed || DEFAULT_VALUES.speed
  )
  const [boundary, setBoundary] = useState<Boundary>(
    props.boundary || DEFAULT_VALUES.boundary
  )
  const [gravity, setGravity] = useState<number>(
    props.gravity || DEFAULT_VALUES.gravity
  )

  useEffect(() => {
    workerRef.current = new Worker(
      new URL('./ParticleLife.worker.ts', import.meta.url)
    )
  }, [])
  useEffect(() => {
    if (fieldRef.current) {
      fieldRef.current.style.backgroundColor = backgroundColor
    }
  }, [backgroundColor, fieldRef.current])
  useEffect(() => {
    for (const p of particlesRef.current || []) {
      applyParticleStyle(p, particleSize)
    }
  }, [particleSize, particlesRef.current])

  const createParticleElement = useCallback(
    (root: HTMLDivElement) => {
      const el = document.createElement('div')
      el.classList.add(styles.particle)
      applyParticleStyle(el, particleSize)
      root.appendChild(el)
      return el as ParticleElement
    },
    [particleSize]
  )

  useEffect(() => {
    const updateParticles = (particles: EmitValues['particles']) => {
      const ts = Date.now()
      if (particles.length > particlesRef.current.length) {
        for (let i = particlesRef.current.length; i < particles.length; i++) {
          particlesRef.current.push(createParticleElement(fieldRef.current))
        }
      } else if (particles.length < particlesRef.current.length) {
        const deleted = particlesRef.current.splice(
          particles.length,
          particlesRef.current.length - particles.length
        )
        for (const d of deleted) {
          if (fieldRef.current.contains(d)) {
            fieldRef.current.removeChild(d)
          }
        }
      }
      setNumParticles(particles.length)
      particles.forEach(([x, y, color], i) => {
        const el = particlesRef.current[i]
        el.style.transform = `translateX(${x}px) translateY(${y}px)`
        el.style.color = color
      })
    }

    if (fieldRef.current && workerRef.current) {
      const { width, height } = fieldRef.current.getBoundingClientRect()
      workerRef.current.postMessage({
        action: 'init',
        param: {
          width,
          height,
          numParticles,
          numColors,
          repulsiveRadius,
          interactRadius,
          friction,
          speed,
          matrix,
        },
      })
      const listener = (e: MessageEvent<EmitValues>) => {
        if ('particles' in e.data) updateParticles(e.data['particles'])
        if ('numColors' in e.data) setNumColors(e.data['numColors'])
        if ('repulsiveRadius' in e.data)
          setRepulsiveRadius(e.data['repulsiveRadius'])
        if ('interactRadius' in e.data)
          setInteractRadius(e.data['interactRadius'])
        if ('friction' in e.data) setFriction(e.data['friction'])
        if ('speed' in e.data) setSpeed(e.data['speed'])
        if ('matrix' in e.data) setMatrix(e.data['matrix'])
        if ('boundary' in e.data) setBoundary(e.data['boundary'])
        if ('gravity' in e.data) setGravity(e.data['gravity'])
      }
      workerRef.current.addEventListener('message', listener)
      return () => workerRef.current.removeEventListener('message', listener)
    }
  }, [
    fieldRef.current,
    workerRef.current,
    particlesRef.current,
    createParticleElement,
  ])
  useEffect(() => {
    const subject = new Subject<void>
    const subscription = subject.pipe(debounceTime(200)).subscribe(() => {
      workerRef.current?.postMessage({
        action: 'resize',
        param: fieldRef.current.getBoundingClientRect(),
      })
    })
    const observer = new ResizeObserver(() => subject.next())
    observer.observe(fieldRef.current)
    return () => {
      observer.disconnect()
      subscription.unsubscribe()
    }
  }, [fieldRef.current])

  const handleClick = (e: React.PointerEvent) => {}

  const getNumberInputHandler = (action: string, isFloat: boolean) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      const param = isFloat
        ? parseFloat(e.target.value)
        : parseInt(e.target.value)
      if (!isNaN(param)) {
        workerRef.current?.postMessage({ action, param })
      }
    }
  }
  const handleChangeBackgroundColor = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => setBackgroundColor(e.target.value)
  const handleChangeParticleSize = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => setParticleSize(e.target.value as ParticleSize)
  const handleChangeBoundary = (e: React.ChangeEvent<HTMLSelectElement>) => {
    workerRef.current?.postMessage({ action: 'updateBoundary', param: e.target.value })
  }
  const handleChangeMatrix = (param: RuleMatrixChangeParam) =>
    workerRef.current?.postMessage({ action: 'updateMatrix', param })
  const handleRandomizePosition = () =>
    workerRef.current?.postMessage({ action: 'randomizePosition' })
  const handleRandomizeMatrix = () =>
    workerRef.current?.postMessage({ action: 'randomizeMatrix' })
  return (
    <div className={styles.container}>
      <div
        className={styles.field}
        ref={fieldRef}
        onPointerDown={handleClick}
      ></div>
      {props.showControl && (
        <div className={styles.control}>
          <div className={styles.hook}><FaCog /></div>
          <button onClick={handleRandomizePosition}>randomize position</button>
          <label>
            <span>number of particles:</span>
            <input
              type="number"
              step="50"
              value={numParticles}
              onChange={getNumberInputHandler('updateNumParticles', false)}
            ></input>
          </label>
          <label>
            <span>particle size:</span>
            <select value={particleSize} onChange={handleChangeParticleSize}>
              {PARTICLE_SIZES.map(size => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>
          </label>
          <label>
            <span>boundary type:</span>
            <select value={boundary} onChange={handleChangeBoundary}>
              {BOUNDARIES.map(boundary => (
                <option key={boundary} value={boundary}>
                  {boundary}
                </option>
              ))}
            </select>
          </label>
          <label>
            <span>number of colors:</span>
            <input
              type="number"
              min="1"
              max="12"
              value={numColors}
              onChange={getNumberInputHandler('updateNumColors', false)}
            ></input>
          </label>
          <RuleMatrixTable
            numColors={numColors}
            matrix={matrix}
            onChange={handleChangeMatrix}
          />
          <button onClick={handleRandomizeMatrix}>randomize matrix</button>
          <label>
            <span>speed:</span>
            <input
              type="number"
              max="10"
              min="1"
              step="0.1"
              value={speed}
              onChange={getNumberInputHandler('updateSpeed', true)}
            ></input>
          </label>
          <label>
            <span>repulsive radius:</span>
            <input
              type="number"
              max="50"
              min="0"
              value={repulsiveRadius}
              onChange={getNumberInputHandler('updateRepulsiveRadius', false)}
            ></input>
          </label>
          <label>
            <span>interact radius:</span>
            <input
              type="number"
              max="100"
              value={interactRadius}
              onChange={getNumberInputHandler('updateInteractRadius', false)}
            ></input>
          </label>
          <label>
            <span>friction:</span>
            <input
              type="number"
              max="50"
              min="0"
              step="0.1"
              value={friction}
              onChange={getNumberInputHandler('updateFriction', true)}
            ></input>
          </label>
          <label>
            <span>gravity to center:</span>
            <input
              type="number"
              max="50"
              min="0"
              value={gravity}
              onChange={getNumberInputHandler('updateGravity', false)}
            ></input>
          </label>
          <label>
            <span>background color:</span>
            <input
              type="color"
              value={backgroundColor}
              onChange={handleChangeBackgroundColor}
            ></input>
          </label>
        </div>
      )}
    </div>
  )
}
