import { Subject, timer, firstValueFrom } from 'rxjs'
import type * as wasm from "wasm-mandelbrot";

export type EmitValues = {
  imageBitmap?: ImageBitmap
  displayingRange: Range
  targetRange: Range
  imageData: ImageData
  maxIteration?: number
}
export type InitParams = {
  width: number
  height: number
  targetRange?: Range
  imageData: ImageData
  maxIteration?: number
}
type Point = {
  x: number
  y: number
  i: number
  j: number
  calculated: boolean
}
export type Range = {
  x1: number
  y1: number
  x2: number
  y2: number
}

export const DEFAULTS = {
  maxIteration: 5000,
  targetRange: {
    x1: -2,
    y1: -1,
    x2: 1,
    y2: 1,
  } as Range,
}

export class MandelbrotLogic {
  private width = 10
  private height = 10
  private displayingRange: Range
  private targetRange: Range = DEFAULTS.targetRange
  private maxIteration = DEFAULTS.maxIteration
  private points: Point[][] = []
  private imageData: ImageData | null = null
  private wasm: typeof wasm | null = null

  private readonly _change$ = new Subject<EmitValues>()
  readonly change$ = this._change$.asObservable()

  private emitChange() {
    this._change$.next({
      imageData: this.imageData,
      displayingRange: this.displayingRange,
      targetRange: this.targetRange,
      maxIteration: this.maxIteration,
    })
  }

  private updateRange() {
    const { x1: tx1, x2: tx2, y1: ty1, y2: ty2 } = this.targetRange
    const targetRatio = (tx2 - tx1) / (ty2 - ty1)
    const canvasRatio = this.width / this.height
    if (canvasRatio > targetRatio) {
      const m = (tx1 + tx2) / 2
      const w = (ty2 - ty1) * canvasRatio
      const [x1, x2] = [m - w / 2, m + w / 2]
      this.displayingRange = { x1, y1: ty1, x2, y2: ty2 }
    } else {
      const m = (ty1 + ty2) / 2
      const h = (tx2 - tx1) / canvasRatio
      const [y1, y2] = [m - h / 2, m + h / 2]
      this.displayingRange = { x1: tx1, y1, x2: tx2, y2 }
    }
  }
  private generatePoints() {
    const points = []
    const { x1, x2, y1, y2 } = this.displayingRange
    for (let j = 0; j < this.height; j++) {
      const y = y1 + (j * (y2 - y1)) / this.height
      const row = []
      for (let i = 0; i < this.width; i++) {
        const x = x1 + (i * (x2 - x1)) / this.width
        row.push({ x, y, i, j, calculated: false })
      }
      points.push(row)
    }
    this.points = points
  }
  private calculateTimestamp = 0
  private async asyncCheckConcurrency(timestap: number) {
    await firstValueFrom(timer(1))
    return this.calculateTimestamp !== timestap
  }
  private async calculateAndDraw() {
    if (this.wasm == null) {
      this.wasm = await import('wasm-mandelbrot');
    }
    const calculateTimestamp = Date.now()
    this.calculateTimestamp = calculateTimestamp
    if (await this.asyncCheckConcurrency(calculateTimestamp)) return
    let count = 0
    for (const r of [10, 3, 1]) {
      for (let j = 0; j < this.height; j += r) {
        for (let i = 0; i < this.width; i += r) {
          const point = this.points[j][i]
          if (!point.calculated) {
            const color = this.wasm.calc(point.x, point.y, this.maxIteration)
            this.drawAreaWithColor(this.points[j][i], r, color)
            point.calculated = true;
            count++
          }
          if (count > 100000) {
            this.emitChange()
            count = 0
            if (await this.asyncCheckConcurrency(calculateTimestamp)) return
          }
        }
      }
    }
    this.emitChange()
  }
  private drawAreaWithColor(point: Point, r: number, color: Uint8Array) {
    const startX = Math.max(point.i - Math.floor(r / 2), 0),
      endX = Math.min(point.i + Math.ceil(r / 2), this.width),
      startY = Math.max(point.j - Math.floor(r / 2), 0),
      endY = Math.min(point.j + Math.ceil(r / 2), this.height)
    for (let k = startX; k < endX; k++) {
      for (let l = startY; l < endY; l++) {
        const p = (k + l * this.width) * 4
        this.imageData.data[p] = color[0]
        this.imageData.data[p + 1] = color[1]
        this.imageData.data[p + 2] = color[2]
      }
    }
  }

  // actions
  init({
    width,
    height,
    targetRange = this.targetRange,
    imageData,
  }: InitParams) {
    this.width = width
    this.height = height
    this.targetRange = targetRange
    this.imageData = imageData
    this.updateRange()
    this.generatePoints()
    this.calculateAndDraw()
  }
  rangeChange(targetRange: Range) {
    this.targetRange = targetRange
    this.updateRange()
    this.generatePoints()
    this.calculateAndDraw()
  }
  resizeCanvas({ width, height, imageData }) {
    this.width = width
    this.height = height
    this.imageData = imageData
    this.updateRange()
    this.generatePoints()
    this.calculateAndDraw()
  }
  settingChange({ target, value }) {
    if (target === 'max-iteration') {
      this.maxIteration = value
      this.generatePoints()
      this.calculateAndDraw()
    }
  }
  reset() {
    this.generatePoints()
    this.calculateAndDraw()
  }
}

export default MandelbrotLogic
