import React, { useState, useRef, useEffect } from 'react'
import { FaExpand, FaCompress } from 'react-icons/fa'
import * as styles from './index.module.scss'

type ExpandStatus = 'compressed' | 'expanding' | 'expanded' | 'compressing'
const TRANSITION = 'all 300ms ease-out'


export default function ExpandableBox({ children, height }) {
  const [expandStatus, setExpandStatus] = useState<ExpandStatus>('compressed')
  const [wrapperStyle, setWapperStyle] = useState<React.CSSProperties>({ height })
  const wapperRef = useRef<HTMLDivElement>(null);
  const styleBeforeExpandRef = useRef<React.CSSProperties>(null);
  useEffect(() => {
    if (expandStatus === 'compressed') {
      setWapperStyle({ height })
    }
  }, [height])
  useEffect(() => {
    // TODO html以外のものがスクロールしてる場合は未対応
    const scrollElement = document.getElementsByTagName('html')[0]
    if (expandStatus === 'expanding') {
      const { width, height, left, top } = wapperRef.current.getBoundingClientRect()
      const styleBeforeExpand = {
        top: top + scrollElement.scrollTop,
        left: left + scrollElement.scrollLeft,
        width, height,
        transition: TRANSITION,
        position: 'absolute' 
      } as React.CSSProperties
      setWapperStyle(styleBeforeExpand)
      styleBeforeExpandRef.current = styleBeforeExpand
      setTimeout(() => {
        setWapperStyle({
          top: scrollElement.scrollTop,
          left: scrollElement.scrollLeft,
          height: window.innerHeight,
          width: window.innerWidth,
          transition: TRANSITION,
          position: 'absolute' 
        })
      }, 1)
    } else if (expandStatus === 'expanded') {
      const originalOverflow = scrollElement.style.overflow
      scrollElement.style.overflow = 'hidden'
      setWapperStyle({
        top: scrollElement.scrollTop,
        left: scrollElement.scrollLeft,
        height: '100vh',
        width: '100vw',
      })
      return () => { scrollElement.style.overflow = originalOverflow }
    } else if (expandStatus === 'compressing') {
      if (styleBeforeExpandRef.current != null) {
        setWapperStyle({
          top: scrollElement.scrollTop,
          left: scrollElement.scrollLeft,
          height: window.innerHeight,
          width: window.innerWidth,
          transition: TRANSITION,
        })
        setTimeout(() => setWapperStyle(styleBeforeExpandRef.current), 1)
      }
    } else if (expandStatus === 'compressed') {
      setWapperStyle({ height })
    }
  }, [expandStatus])
  const handleTransitionEnd = () => {
    if (expandStatus === 'expanding') {
      setExpandStatus('expanded')
    } else if (expandStatus === 'compressing') {
      setExpandStatus('compressed')
    }
  }
  const wrapperClassName = expandStatus in styles ? `${styles.wrapper} ${styles[expandStatus]}` : styles.wrapper
  return (
    <div className={wrapperClassName} ref={wapperRef} style={wrapperStyle} onTransitionEnd={handleTransitionEnd}>
      <div className={styles.innerWrapper}>
        {children}
        <div className={styles.iconWrapper}>
          {expandStatus === 'compressed' && (
            <FaExpand
              className={styles.icon}
              onClick={() => setExpandStatus('expanding')}
            ></FaExpand>
          )}
          {expandStatus === 'expanded' && (
            <FaCompress
              className={styles.icon}
              onClick={() => setExpandStatus('compressing')}
            ></FaCompress>
          )}
        </div>
      </div>
    </div>
  )
}
