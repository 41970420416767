import { Subject } from 'rxjs'
import { scan, throttleTime } from 'rxjs/operators'
import { LoopTimer } from '../../utils/timer'
import { mod, hueToRgb, minMax } from './util'

class Cell {
  constructor(
    readonly i: number,
    readonly j: number,
    public points: ReadonlyArray<number>,
    public generation: number = 240,
    public alive = false,
    public score = 0
  ) {}
}
type DataArrayType = ReadonlyArray<ReadonlyArray<Cell>>
export type InitParams = {
  width?: number
  height?: number
  size?: number
  survive?: number[]
  spawn?: number[]
  speed?: number
  shape?: Shape
  imageData: ImageData
}
export interface PositionParam {
  x: number
  y: number
}
export type EmitValues = {
  imageData?: ImageData
  size?: number
  running?: boolean
  survive?: number[]
  spawn?: number[]
  color?: Color
  speed?: number
  shape?: Shape
}
export type Color = 'generation' | 'mono' | 'density'
export type Shape = 'square' | 'hexagon' | 'triangle'
export const DEFAULTS = {
  size: 10,
  survive: [2, 3],
  spawn: [3],
  speed: 15,
  color: 'generation' as Color,
  shape: 'square' as Shape,
}

// prettier-ignore
const NEIGHBORDIFFS = {
  square: [
    [-1, -1], [0, -1], [1, -1],
    [-1,  0],          [1,  0],
    [-1,  1], [0,  1], [1,  1],
  ],
  hexagon: [
              [0, -1], [1, -1],
    [-1,  0],          [1,  0],
    [-1,  1], [0,  1]
  ],
  triangle_even: [
              [0, -2], [1, -2],
    [-1, -1], [0, -1], [1, -1],
    [-1,  0],          [1,  0],
    [-1,  1], [0,  1],
    [-1,  2], [0,  2],
    [-1,  3],
  ],
  triangle_odd: [
                      [1, -3],
             [0, -2], [1, -2],
             [0, -1], [1, -1],
    [-1, 0],          [1,  0],
    [-1, 1], [0,  1], [1,  1],
    [-1, 2], [0,  2],
  ],
}
const ROOT3 = Math.pow(3, 0.5)
const ROOT1_3 = Math.pow(3, -0.5)

export class GameOfLifeLogic extends LoopTimer {
  private width = 10
  private height = 10
  private maxX = 1
  private maxY = 1
  private size = DEFAULTS.size
  private _speed = DEFAULTS.speed
  private currentGeneration = 240
  private dataArray: DataArrayType = []
  private imageData: ImageData | null = null
  private survive = DEFAULTS.survive
  private spawn = DEFAULTS.spawn
  private color: Color = DEFAULTS.color
  private shape: Shape = DEFAULTS.shape

  private readonly _change$ = new Subject<EmitValues>()
  readonly change$ = this._change$.asObservable().pipe(
    scan((acc, value) => ({ ...acc, ...value }), {}),
    throttleTime(20)
  )

  private emitDataArrayChange() {
    this._change$.next({
      imageData: this.imageData,
      running: this.running,
    })
  }
  private emitSettingChange() {
    this._change$.next({
      size: this.size,
      survive: this.survive,
      spawn: this.spawn,
      color: this.color,
      speed: this.speed,
      shape: this.shape,
    })
  }

  private resizeDataArray() {
    if (this.shape === 'square') {
      this.maxX = Math.ceil(this.width / this.size)
      this.maxY = Math.ceil(this.height / this.size)
    } else if (this.shape === 'hexagon') {
      this.maxX = Math.ceil(this.width / this.size + 0.5)
      this.maxY = Math.ceil((this.height * 2 * ROOT1_3) / this.size + ROOT1_3)
    } else if (this.shape === 'triangle') {
      this.maxX = Math.ceil((this.width * ROOT3) / (2 * this.size) + 0.5)
      this.maxY = Math.ceil(this.height / this.size) * 2
    }
    const array = []
    for (let i = 0; i < this.maxX; i++) {
      const row = []
      const oldRow = this.dataArray[i] || []
      for (let j = 0; j < this.maxY; j++) {
        const cell = this.generateCell(i, j)
        if (oldRow[j]) {
          cell.generation = oldRow[j].generation
          cell.alive = oldRow[j].alive
          cell.score = oldRow[j].score
        } else {
          cell.generation = this.currentGeneration
        }
        row.push(cell)
      }
      array.push(row)
    }
    this.dataArray = array
    this.updateImageDataAll()
    this.emitDataArrayChange()
  }
  private generateCell(i: number, j: number): Cell {
    const points = []
    if (this.shape === 'square') {
      const startX = this.size * i,
        startY = this.size * j,
        endX = Math.min(this.width, startX + this.size),
        endY = Math.min(this.height, startY + this.size)
      for (let k = startX; k < endX; k++) {
        for (let l = startY; l < endY; l++) {
          points.push(k + l * this.width)
        }
      }
    } else if (this.shape === 'hexagon') {
      const centerX = mod(i + j / 2, this.maxX) * this.size,
        centerY = (ROOT3 / 2) * j * this.size,
        startX = Math.max(Math.floor(centerX - this.size / 2), 0),
        endX = Math.min(Math.ceil(centerX + this.size / 2), this.width),
        endCenterX = Math.min(centerX, endX)
      for (let x = startX; x < endCenterX; x++) {
        const startY = minMax(
            Math.floor(-ROOT1_3 * (x - centerX + this.size) + centerY),
            0,
            this.height
          ),
          endY = Math.min(
            Math.ceil(ROOT1_3 * (x - centerX + this.size) + centerY),
            this.height
          )
        for (let y = startY; y < endY; y++) points.push(x + y * this.width)
      }
      for (let x = Math.ceil(centerX); x < endX; x++) {
        const startY = minMax(
            Math.floor(ROOT1_3 * (x - centerX - this.size) + centerY),
            0,
            this.height
          ),
          endY = Math.min(
            Math.ceil(-ROOT1_3 * (x - centerX - this.size) + centerY),
            this.height
          )
        for (let y = startY; y < endY; y++) points.push(x + y * this.width)
      }
    } else if (this.shape === 'triangle') {
      if (j % 2 === 0) {
        const centerX = mod(i * 2 + j / 2, this.maxX * 2) * ROOT1_3 * this.size,
          topY = (j / 2) * this.size,
          bottomY = topY + this.size
        for (let y = Math.floor(topY); y < bottomY; y++) {
          const startX = Math.max(
              Math.floor((y - topY - this.size) * ROOT1_3 + centerX),
              0
            ),
            endX = Math.min(
              Math.ceil((this.size - y + topY) * ROOT1_3 + centerX),
              this.width
            )
          for (let x = startX; x < endX; x++) points.push(x + y * this.width)
        }
      } else {
        const topX =
            mod(i * 2 + (j + 1) / 2, this.maxX * 2) * ROOT1_3 * this.size,
          topY = ((j - 1) / 2) * this.size,
          bottomY = topY + this.size
        for (let y = Math.floor(topY); y < bottomY; y++) {
          const startX = Math.max(Math.floor(topX - ROOT1_3 * (y - topY)), 0),
            endX = Math.min(Math.ceil(topX + ROOT1_3 * (y - topY)), this.width)
          for (let x = startX; x < endX; x++) points.push(x + y * this.width)
        }
      }
    }
    return new Cell(i, j, points)
  }
  private updateImageDataAll() {
    for (const row of this.dataArray) {
      for (const cell of row) {
        this.updateImageDataCell(cell)
      }
    }
  }
  private updateImageDataCell(cell: Cell) {
    if (!this.imageData) {
      return
    }
    const color = this.getCellColor(cell)
    cell.points.map(p => {
      const pos = p * 4
      this.imageData.data[pos] = color.r
      this.imageData.data[pos + 1] = color.g
      this.imageData.data[pos + 2] = color.b
    })
  }
  private getCellColor(cell: Cell) {
    if (cell.alive) {
      if (this.color === 'generation') {
        return hueToRgb(cell.generation)
      } else if (this.color === 'density') {
        return hueToRgb(240 - cell.score * 30)
      } else {
        return { r: 100, g: 100, b: 100 }
      }
    } else {
      return { r: 250, g: 250, b: 250 }
    }
  }
  private updateDataArray() {
    const changes: Cell[] = []
    const redrawSet = new Set<Cell>()
    for (const row of this.dataArray) {
      for (const cell of row) {
        if (cell.alive) {
          if (!this.survive.includes(cell.score)) changes.push(cell)
        } else {
          if (this.spawn.includes(cell.score)) changes.push(cell)
        }
      }
    }
    changes.forEach(cell => {
      cell.alive = !cell.alive
      cell.generation = this.currentGeneration
      redrawSet.add(cell)
    })
    for (const row of this.dataArray) {
      for (const cell of row) {
        const newScore = this.getNeighborCells(cell).filter(
          nCell => nCell.alive
        ).length
        if (this.color === 'density' && newScore !== cell.score)
          redrawSet.add(cell)
        cell.score = newScore
      }
    }
    ;[...redrawSet].forEach(cell => this.updateImageDataCell(cell))
  }

  private getNeighborDiffs(cell: Cell) {
    if (this.shape === 'triangle') {
      if (cell.j % 2) return NEIGHBORDIFFS.triangle_even
      return NEIGHBORDIFFS.triangle_odd
    }
    return NEIGHBORDIFFS[this.shape]
  }
  private getNeighborCells(cell: Cell) {
    return this.getNeighborDiffs(cell).map(([di, dj]) => {
      const ni = mod(cell.i + di, this.maxX),
        nj = mod(cell.j + dj, this.maxY)
      return this.dataArray[ni][nj]
    })
  }
  private getCellByPosition({ x, y }: PositionParam): Cell | null {
    if (x < 0 || this.width <= x) return null
    if (y < 0 || this.height <= y) return null
    if (this.shape === 'square') {
      const i = Math.floor(x / this.size),
        j = Math.floor(y / this.size)
      return this.dataArray[i][j]
    } else if (this.shape === 'hexagon') {
      const j = Math.floor((2 * ROOT1_3 * y) / this.size + ROOT3 / 4),
        i = mod(Math.floor(((2 * x) / this.size - j + 1) / 2), this.maxX)
      return this.dataArray[i][j]
    } else if (this.shape === 'triangle') {
      const i = mod(
          Math.floor((x * ROOT3 - y + this.size) / 2 / this.size),
          this.maxX
        ),
        n = Math.floor(y / this.size),
        k =
          (Math.floor((x * ROOT3 + y + this.size) / 2 / this.size) + n + i) % 2
      return this.dataArray[i][2 * n + k]
    }
  }

  // actions
  init({
    width = this.width,
    height = this.height,
    size = this.size,
    survive = this.survive,
    spawn = this.spawn,
    speed = this.speed,
    shape = this.shape,
    imageData,
  }: InitParams) {
    this.width = width
    this.height = height
    this.size = size
    this.survive = survive
    this.spawn = spawn
    this.shape = shape
    this.speed = speed
    this.imageData = imageData
    this.resizeDataArray()
  }
  click({ x, y, toggle = true }) {
    const cell = this.getCellByPosition({ x, y })
    if (!cell) return
    if (!toggle && cell.alive) return
    cell.alive = !cell.alive
    cell.generation = this.currentGeneration
    const changes = [cell]
    const scoreChange = cell.alive ? 1 : -1
    this.getNeighborCells(cell).map(nCell => {
      nCell.score += scoreChange
      if (nCell.alive) changes.push(nCell)
    })
    changes.forEach(cell => this.updateImageDataCell(cell))
    this.emitDataArrayChange()
  }
  settingChange({ target, value }: any) {
    if (target === 'spawn') {
      this.spawn = value
    } else if (target === 'survive') {
      this.survive = value
    } else if (target === 'speed') {
      this.speed = value
    } else if (target === 'size') {
      this.size = value
      this.resizeDataArray()
    } else if (target === 'color') {
      this.color = value
      this.updateImageDataAll()
    } else if (target === 'shape') {
      this.shape = value
      this.resizeDataArray()
    }
    this.emitSettingChange()
  }
  resizeCanvas({ width, height, imageData }) {
    this.width = width
    this.height = height
    this.imageData = imageData
    this.resizeDataArray()
  }
  reset() {
    this.dataArray = []
    this.resizeDataArray()
  }

  // loopTimer
  protected task() {
    this.currentGeneration = (this.currentGeneration + 1) % 360
    this.updateDataArray()
  }
  protected completed() {
    this.emitDataArrayChange()
  }
  get speed() {
    return this._speed
  }
  set speed(val) {
    this._speed = val
    this.waitInterval = 1000 / val
  }
}

export default GameOfLifeLogic
